body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4d4d4d;
  overflow-x: hidden;

}
.had_size{
  font-size: 65px !important;
}

.contact-info {
  height: 44rem !important;
}

.header-logo {
  width: 15rem !important;
  height: 7rem !important;
}

.home-content-height {
  height: 800px !important;
}

.font_style {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}

.margin_top {
  margin-top: 16%;
}

.nav-text {
  color: white;
  padding: 23px 30px;
  font-size: 18px;

  cursor: pointer;
}

.text-dark-white {
  color: black;
}
.most{
  height: 951px !important;
}

@media screen and (max-width: 1500px) {
  
  .nav-text {

    font-size: 12px;
    font-weight: 700 !important;

  }
  .most{
    height: 980px !important;
  }

  .text-dark-white {
    color: black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 580px) {
  .text-dark-white {
    color: white;
  }

  .nav-text {
    margin-bottom: 20px !important;
  }

  .partner-images {
    margin-top: 4rem;
    padding: 0 5rem;
  }
}

.mainDiv {
  position: relative;
  height: 1100px;
  width: 100%;
  align-items: center;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./Assets/Images/lightban.jpg);
  margin-top: 70px;
  overflow: hidden;
}

.mainDiv::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.mainDiv * {
  position: relative;
  z-index: 2;
}

.mainDiv2 {
  height: 75vh;
}

.mainDiv::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  background: linear-gradient(to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0));
  z-index: 1;
}

.add_img_height_width {
  height: 153rem;
}

.add_img_height_width_text {
  width: 100%;
  height: 100px;
  ;
  padding: 20px;

}

.card {
  position: relative;
  overflow: hidden;
}

.section_card {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.text-dark-white {
  font-size: 15px !important;
}

.card:hover .section_card {
  opacity: 1;
  animation: slideUp 0.2s ease forwards;
  /* Trigger the slide-up animation on hover */
}

.card:hover .section_card1 {
  display: none;
  opacity: 1;
  animation: slideUp 0.2s ease forwards;
  /* Trigger the slide-up animation on hover */
}

@keyframes slideUp {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

.achievement-item {
  transition: transform 0.3s ease-in-out;
}

.achievement-item:hover .achievement-icon {
  transform: scale(1.1);
}

.achievement-icon,
.achievement-number {
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.achievement-item:hover .achievement-icon {
  color: white !important;
  padding: 10px;
  background-color: #ffa200;
  border-radius: 100%;
  transform: scale(1.2);
}

.container .col {
  height: 8vw;
  margin-bottom: 20px;
}

.card:hover {
  height: 35vw;
}

.made:hover {
  height: 55vw;
}

.container .col .card {
  max-width: 400px;
  height: 100%;
  position: relative;
  border: 0px;
  perspective: 1000px;
}

/* popup */
.modal-body {
  padding: 2rem;
}

.text-success {
  color: #28a745;
}

button.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.container .col .card__side {
  border-radius: 2px;
  height: 100%;
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: all 0.8s ease-in-out;
}

.container .col .card__side .frame {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}

.container .col .card__side--front .frame p {
  color: white;
  font-family: serif;
  font-weight: 200;
  font-size: 32px;
  text-shadow: 2px 2px 5px #222;
}

/* Styling the back side of the card */
.container .col .card__side--back {
  background-color: #f7f7f7;
  transform: rotateY(180deg);
}

.container .col .card__side--back .frame {
  border: solid 1px rgba(34, 34, 34, 0.5);
  position: relative;
}

.container .col .card__side--back .frame p {
  width: 70%;
  text-align: justify;
  line-height: 1.5;
}

.container .col .card__side--back .frame small {
  position: absolute;
  bottom: 20px;
}

.card:hover .card__side--front {
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  transform: rotateY(0);
}

h1 {
  margin-bottom: 2rem;
}

ul {
  list-style: square;
}

li {
  margin-bottom: 10px;
}

@media (min-width:1000px) and (max-width: 1500px) {

  .margin_top{
    margin-top: 29%!important;
  }
  .made:hover {
    height: 90vw !important;
  }

}

@media (max-width: 1200px) {

  .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .container .col {
    height: 500px;
  }
}

@media (max-width: 850px) {
  .container {
    grid-template-columns: 1fr;
  }

  .text-dark-white {
    color: black;
  }
  .top-heading {
    font-size: 30px !important;
  }
}



.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}


.our-service {
  font-size: 45px !important;
}
.secnd_had{
  font-size: 65px !important;
}
.scnd_para{
  font-size: 30px !important;
}
.card_para{
  line-height: 35px !important;
}
.achivment{
  height: 100% !important;
}
.achivment2{
  height: 100% !important;
}

@media screen and (max-width: 768px) {
  .mobile_img{
    height: 515px !important;
  }
  .contact-info {
    height: 40rem !important;
}
.most {
  height: 610px !important;
}

.form-input {
  height: 41px!important;
}
  .send-msg {
    font-size: 25px !important;
    text-align: center !important;
}
  .achivment{
    height: 100% !important;
  }
  .achivment2{
    height: 100% !important;
  }
  .card:hover {
    height: 50vw !important;
  }
  .header-logo {
    height: 5rem !important;
    width: 11rem !important;
}


  /* .made :hover{
    height: 250px;
  } */
  .slide {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
  .card_logo {
    max-width: 300px;
    height: 188px !important;
  }
  .process-card{
    height: 20rem !important;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .margin_top{
    margin-top: 50%!important;
  }
  .card_para{
    line-height: 13px !important;
  }
  .scnd_para{
    font-size: 15px !important;
  }
  .had_size{
    font-size: 30px !important;
  }
  .home-content-height{
    height: 680px !important;
  }
  .text-dark-white {
    color: white;
    font-size: 13px;
  }

  .nav-text {
    margin-top: 30px;
  }



 .secnd_had{
  font-size: 30px !important;
 }
  .list-heading{
    font-size: 17px !important;
  }


}


.top-heading {
  font-size: 65px !important;
}


.card-img {
  width: 100% !important;
  height: 100%;
  filter: brightness(75%);
}

.send-msg {
  font-size: 36px;
  text-align: start ;
}



.label-size {
  font-size: 16px !important;
  text-align: start !important;

}

.Avant-grade-heading {
  font-size: 65px !important;
}

.Avant-grade-para {
  font-size: 30px !important;
}

.process-card {
  height: 46rem ;
}



@media (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: var(--shadow);
  color: var(--dark);
  text-decoration: none;
  transition: all 0.25s;
  position: relative;
  height: 27.3rem;
}

@media (min-width: 992px) {
  .card.--inactive {
    display: none;
  }

  .text-dark-white {
    color: black;
  }
}

@media (min-width: 1400px) {

  .card:nth-child(5n) .card__location {
    color: var(--light);
  }
}

.contact-info {
  height: 40rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  border-radius: 10px 0 0 10px;
}

.card-hover-text {

  line-height: 30px;
  word-spacing: 8px;
}

.customer-logos .slide img {
  height: 144px;
  /* Set a fixed height */

}

/* .customer-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
  width: 100%;
} */

/* .card-hover-heading{
  font-size: 28px;
} */
/* .card-hover-para{
  font-size: 16px !important;
} */

/* .contact-detail{
  height: 320px ;
} */
.form-input {
  height: 60px;
}

.responsive_logo {
  width: 16rem;
}

.text-base {
  font-size: 16px;
  line-height: 20px;
}

.enquiry-form {
  border-radius: 0 10px 10px 0;
  /* height: 40rem; */
}

/* / CardNews.css / */


.legend {
  padding: 14px;
  text-align: left;
  height: 12.8rem;
  border-radius: 0px 0px 20px 20px;
}

.legend h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.legend p {
  margin: 5px 0 0;
  font-size: 14px;
}

.nav-item {
  color: white !important;
  font-weight: 500;
}

.hover_effect:hover {
  /* background-color: rgb(248 240 232 / 40%); */

  text-decoration: none !important;
}

.hover_effect {
  text-decoration: none !important;
  font-weight: 500;
}

.legend h3 {
  margin-bottom: 5px;
}

.legend p {
  font-size: 14px;
  margin-bottom: 0;
}

.nav {
  position: fixed;
  /* top: 0.3rem; */
  left: 0;
  right: 0;
  width: 90%;
  margin: auto;
  padding: 0.1rem 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  backdrop-filter: blur(10px);
  overflow: hidden;
  z-index: 100;
}

.nav .tag {
  position: relative;
  display: inline-block;
  padding: 0.1rem;
  color: inherit;
  text-decoration: none;
  letter-spacing: 2px;
}

.cards {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.card_data {
  width: 100% !important;
}

.cards .card p.tip {
  font-size: 1em;
  font-weight: 700;
}

.cards .card p.second-text {
  font-size: 0.7em;
}

.cards .card:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.post-date {
  font-size: 14px;
  line-height: 20px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.show {
  h1 {
    text-shadow: 2px 2px red;
  }
}

.font-color a {
  color: #ff5e14;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 350px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.footer-widget-heading h3 {
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}

.footer-widget ul li a {
  color: black;
  text-transform: capitalize;
  text-decoration: none;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {

  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;

}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.add_width_height_facein {
  width: 30px;
  height: 30px;
}

.alltext-justify {
  text-align: justify !important;
}

.img_shadow {
  box-shadow: 22% 120% 50% 40% / 37% 60% 90% 99%;
}

.height_banner {

  height: 100%;
}

.banner_heading_para {
  clip-path: polygon(0% 0, 106% 0%, 80% 100%, 0% 100%);
  background-color: white;
  /* width: 100%; */


  font-weight: 900;

  line-height: 49px;
}

.banner_heading {
  clip-path: polygon(0% 0, 106% 0%, 80% 100%, 0% 100%);
  background-color: black;

}

.banner_heading_para h3 {
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
}

.nav .nav-item {
  color: #fff;
  /* Default text color */
}

.active {
  /* background-color: rgba(255, 255, 255, 0.3); */
  /* color: #000; */
  text-decoration: none !important;
  /* font-weight: 700; */
}

.fs_size {
  font-size: 70px !important;
}

.banner_line {
  border-color: rgb(255, 162, 0) !important;
  border: 30px !important;
  width: 40px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Verdana, sans-serif;
  margin: 0;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
}

/* Slides */
.mySlides {
  display: none;
  padding: 80px;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;

  .horizontal-line {
    border-top: 1px solid black;
    /* Adjust color and thickness as needed */
    width: 100%;
    /* Ensures the line spans the entire width */
    margin-left: auto;
    /* Pushes the line to the right */
  }
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active,
.dot:hover {
  font-weight: bold;
  color: #ffa200 !important;
}

/* Add an italic font style to all quotes */
q {
  font-style: italic;
}

/* Add a blue color to the author */
.author {
  color: cornflowerblue;
}

.box {
  height: 19px;
  border-right: 2px solid #ffc45f;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 500px;
}

.marquee-content {
  display: inline-block;
  padding-right: 100%;
  animation: marquee 60s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.border_style {
  border-left: 5px solid rgb(255, 162, 0);
}

.card_logo {
  height: 12rem !important;

}

.show {
  height: 200px;
  width: 500px;
  /* margin: 100px auto; */
  position: relative;
}

.img_gallery {
  height: 180px;
  /* position: absolute; */
  left: 0;
  /* offset-path: path('m 0 50 q 50-30 100-30 t 100 30 100 0 100-30 100 30'); */
  /* box-shadow: 1px 1px 3px #0008; */
  transition: transform 0.4s ease-out,
    offset-path 0.4s cubic-bezier(0.77, -1.17, 0.75, 0.84), box-shadow 0.3s,
    z-index 0.3s;
  z-index: 0;
}

.img_gallery:hover {
  transform: scale(2);
  /* on hover, the path gets a bit shorter & flattened & shifted to left/bottom a bit for nicer movement */
  offset-path: path("m 5 65 q 45-0 90-0 t 90 0 90 0 90-0 90 0");
  /* box-shadow: 3px 4px 10px #0006; */
  /* ensures that image gets on top of stack at the start of "popping" animation
     and gets back at the end of getting back. With smaller value, 2 different transitions would be needed */
  z-index: 999;
}



.block1 {
  clip-path: polygon(0% 0%, 76% 0%, 101% 101%, 25% 100%)
    /* clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%); */
}

.blocks {
  display: flex;
  height: 32vh;
  /* width: 142vh; */
  overflow: hidden;
}

.block {
  flex: 1;
  clip-path: polygon(-6% 0%, 70% 0%, 90% 101%, 14% 100%);
  /* clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%); */
  transition: 0.85s flex 0.185s linear;
  will-change: flex;
  margin-bottom: 7px;
}

.blocks .block img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.blocks .block:first-child img {
  filter: grayscale(0);
}

.blocks .block a {
  position: relative;
}

.blocks .block a div {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: #fff;
  font-size: clamp(12px, 5cqw, 32px);
  z-index: 2;
}

.blocks .block:hover {
  flex: 6.25;
}

.blocks .block:hover img {
  filter: grayscale(0);
}



.blocks .block:last-child {

  /* clip-path: polygon(20% 0, 100% 0%, 80% 100%, 0% 100%); */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.scroll {

  height: 60vh;
  /* Adjust this as needed */
  width: 347px;
  /* Match the image width */
  overflow: hidden;
  /* Hide the overflowing content */
}

.scroll ul {
  padding: 0;
  border-radius: 10px;
  animation: scroll 10s infinite linear;
}

.scroll ul:hover {
  animation-play-state: paused;
}

.scroll li {
  margin: 10px 0;
  /* Vertical margin */
  list-style: none;
  position: relative;
  border-radius: 10px;
}

.scroll img {
  height: 400px;
  width: 470px;
  /* Make the image fill the container */
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  border-radius: 10px;
  max-width: fit-content;
}

.scroll img:hover {
  transform: scale(1.04);
}

@keyframes scroll {
  from {
    margin-top: 0%;
  }

  to {
    margin-top: -100%;
  }
}

.rol {
  transform: translate(-61px, 10px) !important;
}

.rol_1 {
  transform: translate(-122px, 10px) !important;

}

.text_justifly {
  text-align: justify;
}

.asset {
  position: relative;
  height: 1100px;
  width: 100%;
  align-items: center;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  background-image: url(./Assets/Images/asset\ managment.jpg);
  overflow: hidden;



}

.asset::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.asset * {
  position: relative;
  z-index: 2;
  /* Ensure any child elements are above the gradient overlay */
}

.part {
  position: relative;
  height: 1100px;
  align-items: center;
  width: 100%;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  background-image: url(./Assets/Images/parts\ managment.jpg);
  overflow: hidden;

}

.part::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.part * {
  position: relative;
  z-index: 2;
  /* Ensure any child elements are above the gradient overlay */
}

.const {
  position: relative;
  height: 1100px;
  align-items: center;
  width: 100%;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  background-image: url(./Assets/Images/construction-works-frankfurt-downtown-germany.jpg);
  overflow: hidden;

}

.const::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.const * {
  position: relative;
  z-index: 2;
  /* Ensure any child elements are above the gradient overlay */
}

.make {
  position: relative;
  height: 1100px;
  align-items: center;
  width: 100%;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  background-image: url(./Assets/Images/makeinindea.jpg);
  overflow: hidden;

}

.make::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.make * {
  position: relative;
  z-index: 2;
  /* Ensure any child elements are above the gradient overlay */
}

.api {
  position: relative;
  height: 1100px;
  align-items: center;
  width: 100%;
  position: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  background-image: url(./Assets/Images/API.jpg);
  overflow: hidden;

}

.api::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  /* Adjust the width as needed */
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 1;
  /* Ensure the gradient overlay is on top of the background image */
}

.api * {
  position: relative;
  z-index: 2;
  /* Ensure any child elements are above the gradient overlay */
}


:root {
  --border-radius: 10px;
}



.card .ban_card,
.item .ban_card {
  width: 90%;
  height: 90%;
}

.item img {
  object-fit: cover;
}

.card-container:hover .card {
  margin: 0 10px;
  transform: rotate(0deg) translateY(0) translateX(0);
}



.grid-area {
  display: grid;

  /* grid-template-rows: repeat(7, 100px); */
  gap: 20px;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#fff2, transparent);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.art-one {
  grid-area: 1 / 1 / 3 / 3;
}

.art-two {
  grid-area: 1 / 3 / 4 / 5;
}

.art-three {
  grid-area: 1 / 5 / 3 / 7;
}

.art-four {
  grid-area: 1 / 7 / 3 / 9;
}

.art-six {
  grid-area: 4 / 3 / 6 / 5;
}

.art-five {
  grid-area: 3 / 1 / 5 / 3;
}

.art-seven {
  grid-area: 3 / 5 / 5 / 7;
}

.art-eight {
  grid-area: 3 / 7 / 6 / 9;
}

.art-nine {
  grid-area: 5 / 1 / 8 / 3;
}

.art-ten {
  grid-area: 6 / 3 / 8 / 5;
}

.art-eleven {
  grid-area: 5 / 5 / 8 / 7;
}

.art-twelve {
  grid-area: 6 / 7 / 8 / 9;
}





@media (max-width: 1400px) {
  .intro {
    gap: 30px;
    padding: 80px 70px;
  }

  .intro-content {
    padding-right: 70px;
  }

  .btn {
    padding: 1rem 3rem;
  }
}

@media (max-width: 975px) {
  .intro {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
    grid-template-areas:
      "card-container"
      "intro-content";
    padding: 40px 60px 30px;
  }

  .intro-content {
    grid-area: intro-content;
    padding-right: 0;
    text-align: center;
  }

  .card-container {
    grid-area: card-container;
  }

  .card {
    max-width: 150px;
  }

  .featured {
    padding: 60px 70px 80px;
  }

  .grid-area {
    display: grid;
    grid-template-columns: repeat(6, 100px);
    grid-template-rows: repeat(9, 100px);
    gap: 20px;
    margin: 40px 30px 60px;
  }

  .art-four {
    grid-area: 8 / 1 / 10 / 3;
  }

  .art-eight {
    grid-area: 8 / 3 / 10 / 5;
  }

  .art-twelve {
    grid-area: 8 / 5 / 10 / 7;
  }
}

@media (max-width: 750px) {
  .grid-area {
    grid-template-columns: repeat(6, 90px);
    grid-template-rows: repeat(9, 90px);
  }

  .featured {
    padding: 60px 50px 80px;
  }

  .slide {
    font-size: 2rem;
    padding: 14px;
  }

  .prev {
    left: 5%;
  }

  .next {
    right: 5%;
  }
}

@media (max-width: 670px) {
  .grid-area {
    grid-template-columns: repeat(4, 110px);
    grid-template-rows: repeat(14, 110px);
  }

  .art-three {
    grid-area: 11 / 3 / 13 / 5;
  }

  .art-four {
    grid-area: 8 / 1 / 10 / 3;
  }

  .art-seven {
    grid-area: 13 / 3 / 15 / 5;
  }

  .art-eight {
    grid-area: 8 / 3 / 11 / 5;
  }

  .art-eleven {
    grid-area: 12 / 1 / 15 / 3;
  }

  .art-twelve {
    grid-area: 10 / 1 / 12 / 3;
  }
}

@media (max-width: 530px) {
  .intro {
    grid-template-rows: 45% 45%;
    gap: 20px;
    padding: 10px 60px 60px;
  }

  .intro-content {
    margin-top: 90px;
  }

  .grid-area {
    grid-template-columns: repeat(4, 90px);
    grid-template-rows: repeat(14, 90px);
  }
}

@media (max-width: 460px) {
  .intro {
    padding: 10px 30px 60px;
  }

  .card {
    max-width: 402px;
    height: 120px !important;
  }
  

  .card:hover {
    max-width: 402px;
    height: 490px !important;
  }
  .made:hover {
    max-width: 402px;
    height: 660px !important;
  }

  .card-hover-text {
    font-size: 10px !important;
    margin: 0 !important;
    line-height: 11px;
    word-spacing: 3px;

  }

  .card-hover-heading {
    font-size: 20px;
  }

  .card-hover-para {
    font-size: 10px !important;
  }

  .grid-area {
    grid-template-columns: repeat(4, 70px);
    grid-template-rows: repeat(14, 70px);
  }

  .featured {
    padding: 60px 40px 80px;
  }

  .featured h1 {
    margin-bottom: 0;
  }

  .img-container p {
    padding: 10px;
  }

  .slide {
    font-size: 1.4rem;
  }
}

.slide{
  width: 220px;
  height: 150px;
}

.slider-wrapper{
  position: relative;

  width: 100%;
  margin: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
  top: 0;
  bottom: 0;
}
.slider-track{
  display: flex;
  width: calc(250*6);
  animation: slidescroll 40s linear infinite;
}
@keyframes slidescroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-1000px*3));
  }
}


.slide img {
 
  /* object-fit: cover; */
}
.slider-wrapper::before,
.slider-wrapper::after {
 
 

  z-index: 2;
}
.slider-wrapper::before {
  left: 0;
  top: 0;
}
.slider-wrapper::after{
  right: -2px;
  top: 0;
  transform: rotateZ(180deg);
}


.com_imge {
  height: 400px;
  aspect-ratio: 1.5 / 1;
  display: flex;
  overflow: hidden;
}

.com_imge .img.active {
  width: 200%;
}

.com_imge:has(.img.active) .img:not(.active) {
  filter: grayscale(100%);
}

.com_imge img {
  height: 100%;
  width: calc(100% / 5);
  object-fit: cover;
  overflow: hidden;
  border: 2px solid #141414;
  transition: all 0.4s cubic-bezier(0.32, 0, 0.67, 0);
  cursor: pointer;
}
h1{
  text-shadow: none!important;
}

